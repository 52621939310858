import { deduplicate } from "@src/utils";
import { useFeatureFlags, useLogoutOnError } from "@src/hooks";
import { useMemberApi } from "./useMemberApi";
import { useMerits } from "../issuance";
import { useQuery } from "@tanstack/react-query";
import type { Container } from "@merit/issuance-client";
import type {
  ExportCredentialsRequestBody,
  ExportCredentialsResponse,
} from "@merit/person-experience-backend-client";
import type { UseQueryResult } from "@tanstack/react-query";

type QueryKey = readonly [
  "credentials",
  { readonly containerIds: ExportCredentialsRequestBody["containerIds"] }
];

type UseCredentialsQueryKey = (containerIds: readonly Container["id"][]) => QueryKey;

// TODO@tyler: Not sure this is worth it
const alphabetically = (a: string, b: string) => a.localeCompare(b);

export const useCredentialsQueryKey: UseCredentialsQueryKey = _containerIds => {
  const containerIds = _containerIds.filter(deduplicate(id => id)).sort(alphabetically);

  return ["credentials", { containerIds }];
};

export type UseCredentials = (
  containerIds: readonly Container["id"][]
) => UseQueryResult<ExportCredentialsResponse>;

/**
 * Hook to get possible credentials from a list of containerIds
 * @param {Container["id"][]} containerIds limits retrieval to only the given list
 */
export const useCredentials: UseCredentials = _containerIds => {
  const { data: featureFlags } = useFeatureFlags();
  const { api: memberApi, isReady } = useMemberApi();
  const { isFetched } = useMerits();
  const { onError } = useLogoutOnError();
  const queryKey = useCredentialsQueryKey(_containerIds);

  return useQuery({
    enabled: isFetched && isReady && featureFlags?.memberShowStudioFrontend === true,
    onError,
    queryFn: ctx => {
      const { containerIds } = ctx.queryKey[1];

      return memberApi.exportCredentials({ body: { containerIds } });
    },
    queryKey,
  });
};
