import { useAccountFolioFromAdminMerit } from "@src/api/person-experience-backend";
import type { Merit } from "@src/api/issuance";

type UseAdminMeritOrgAccountFolioParams = {
  readonly adminMeritId: Merit["id"];
};

const useAdminMeritOrgAccountFolioDetails = ({
  adminMeritId,
}: UseAdminMeritOrgAccountFolioParams) => {
  const { data, isError, isFetched, isLoading } = useAccountFolioFromAdminMerit(adminMeritId);

  return {
    folio: data,
    showEmptyState: isLoading || isError,
    showLoadingState: !isFetched,
  };
};

export { useAdminMeritOrgAccountFolioDetails };
