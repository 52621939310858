import { Card, Error, NotificationSkeleton, Stack } from "@src/components";
import { EmptyNotification } from "../EmptyNotification";
import { MAX_PENDING_MERITS_BEFORE_HIDING_NOTIFICATIONS } from "./constants";
import { Notification } from "../Notification";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { hasState } from "@src/utils";
import { useGetTestProps } from "@src/hooks";
import { useMemo, useState } from "react";
import { useMerits } from "@src/api/issuance";
import { useTheme } from "@merit/frontend-components";
import type { Merit } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

const ELEVATION = 5;
const MIN_CARD_HEIGHT = 140; // taken from sigma-monorepo's og implementation, plus some to support 3 lines of description

export const Notifications = () => {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly backgroundCard1: ViewStyle;
    readonly backgroundCard2: ViewStyle;
    readonly backgroundCardCommon: ViewStyle;
    readonly card: ViewStyle;
    readonly errorContainer: ViewStyle;
    readonly marginBottom: ViewStyle;
  }>({
    backgroundCard1: {
      opacity: 0.6,
      transform: [
        // without this, the "peek" of bg cards on wider screens start to go out of bounds
        // rather than derive the appropriate formula, just opted to do an approximation FYI
        { rotateZ: `${Math.atan(MIN_CARD_HEIGHT / width / 8)}rad` },
        { translateX: theme.spacing.xs },
      ],
      ...theme.elevations[`depth${ELEVATION}` as const],
    },
    backgroundCard2: {
      opacity: 0.8,
      transform: [
        { rotateZ: `-${Math.atan(MIN_CARD_HEIGHT / width / 10)}rad` },
        { translateX: -theme.spacing.xs },
      ],
      ...theme.elevations[`depth${ELEVATION}` as const],
    },
    backgroundCardCommon: {
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    card: {
      minHeight: MIN_CARD_HEIGHT,
    },
    errorContainer: {
      justifyContent: "center",
    },
    marginBottom: {
      marginBottom: theme.spacing.m,
    },
  });

  const getTestProps = useGetTestProps();
  const { data: merits, isError } = useMerits();

  const [dismissedContainerIds, setDismissedContainerIds] = useState<readonly Merit["id"][]>([]);

  const pendingMerits = useMemo(() => merits?.filter(hasState(["pending"])) ?? [], [merits]);

  const displayedMerits = useMemo(
    () => pendingMerits.filter(m => !dismissedContainerIds.includes(m.id)),
    [pendingMerits, dismissedContainerIds]
  );

  if (pendingMerits.length > MAX_PENDING_MERITS_BEFORE_HIDING_NOTIFICATIONS) {
    return null;
  }

  if (isError) {
    return (
      <View style={styles.marginBottom}>
        <Card elevation={ELEVATION} style={[styles.card, styles.errorContainer]}>
          <Error />
        </Card>
      </View>
    );
  }

  return (
    <View
      style={styles.marginBottom}
      {...getTestProps({
        elementName: "Notifications",
      })}
    >
      <Card style={[styles.backgroundCardCommon, styles.backgroundCard1]} />
      <Card style={[styles.backgroundCardCommon, styles.backgroundCard2]} />
      <Stack
        elementId="pendingMeritNotifications"
        items={displayedMerits}
        keyExtractor={container => container.id}
        onDismiss={item => {
          // inline function so typescript can infer the parameter types properly
          setDismissedContainerIds(prev => prev.concat([item.id]));
        }}
        renderEmpty={() => (
          <Card elevation={ELEVATION} style={styles.card}>
            <EmptyNotification />
          </Card>
        )}
        renderItem={(container, index) => (
          <Card style={styles.card}>
            <Notification container={container} counterValue={index + 1} />
          </Card>
        )}
        renderUndefined={() => (
          <Card elevation={ELEVATION} style={styles.card}>
            <NotificationSkeleton />
          </Card>
        )}
      />
    </View>
  );
};
