import {
  AccessibilityWidget,
  AlertContainer,
  TextContextProvider,
  useTheme,
} from "@merit/frontend-components";
import { AutoLogout } from "@src/components";
import { DdScreenReplayProvider } from "./components/DdScreenReplayProvider";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { I18nProvider } from "@lingui/react";
import { Log, addGlobalContext } from "@src/utils";
import { Platform, StatusBar, StyleSheet } from "react-native";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Router } from "./Router";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  arMessages,
  deMessages,
  enMessages,
  esMessages,
  frMessages,
  htMessages,
  koMessages,
  pseudoLocaleMessages,
  ruMessages,
  tlMessages,
  viMessages,
  zhMessages,
} from "@src/locales";
import {
  channel,
  createdAt,
  isEmbeddedLaunch,
  isEmergencyLaunch,
  isUsingEmbeddedAssets,
  releaseChannel,
  runtimeVersion,
  updateId,
} from "expo-updates";
import { i18n } from "@lingui/core";
import { useAlertStore } from "./stores/alert";
import { useEffect, useState } from "react";
import { useFeatureFlags, usePersonExperienceFonts } from "./hooks";
import { useLocales } from "expo-localization";
import { usePreferencesStore } from "./stores";
import ErrorBoundary from "react-native-error-boundary";
import type { ViewStyle } from "react-native";

const DEFAULT_LOCALE = "en";
const PSEUDO_LOCALE = "pseudo-LOCALE";
// TODO: for language *tags* (e.g. with region, like es-MX, en-GB), need to define both tag and code without the region
const SUPPORTED_LANGS_AND_MESSAGES = {
  [PSEUDO_LOCALE]: pseudoLocaleMessages,
  ar: arMessages, // Arabic
  de: deMessages, // German
  en: enMessages, // English
  es: esMessages, // Spanish
  fr: frMessages, // French
  ht: htMessages, // Haitian Creole
  ko: koMessages, // Korean
  ru: ruMessages, // Russian
  tl: tlMessages, // Tagalog
  vi: viMessages, // Vietnamese
  zh: zhMessages, // Chinese (Traditional)
};
i18n.load(SUPPORTED_LANGS_AND_MESSAGES);

const App = () => {
  const { theme } = useTheme();
  const [fontsLoaded, fontsError] = usePersonExperienceFonts();
  const alertList = useAlertStore(state => state.alertList);
  const { data: featureFlags, isFetched: isFeatureFlagsFetched } = useFeatureFlags();
  const languageCode = usePreferencesStore(state => state.languageCode);

  const [hasLogged, setHasLogged] = useState(false);
  useEffect(() => {
    if (hasLogged) {
      return;
    }
    addGlobalContext("expoUpdatesInfo", {
      channel,
      createdAt,
      isEmbeddedLaunch,
      isEmergencyLaunch,
      isUsingEmbeddedAssets,
      releaseChannel,
      runtimeVersion,
      updateId,
    });
    Log.info("app cold start");
    setHasLogged(true);
  }, [hasLogged]);

  const locales = useLocales();
  const activateLocale = (locale: string) => {
    i18n.activate(locale);
    Log.info("locale set to", i18n.locale);
  };
  const noop = () => {
    /**/
  };
  useEffect(() => {
    // initial state
    if (!isFeatureFlagsFetched) {
      if (i18n.locale === "") {
        // TODO: initial state should be last known preferred language either from jwt claims or local store
        activateLocale(languageCode ?? DEFAULT_LOCALE);
      }

      return noop;
    }

    if (languageCode !== undefined && i18n.locale !== languageCode) {
      // this case is when a user changes the language, and the stored value changes
      activateLocale(languageCode);

      return noop;
    }

    // pseudolocalization, when turned on, overrides all since this is explicitly for testing
    if (featureFlags?.experimentMemberAppPseudolocalization === true) {
      activateLocale(PSEUDO_LOCALE);

      return noop;
    }

    // auto language detection using expo-localization
    if (featureFlags?.memberAppAllowAutoLanguageDetection === true) {
      // eslint-disable-next-line functional/no-loop-statement
      for (const locale of locales) {
        if (locale.languageCode !== null) {
          if (Object.keys(SUPPORTED_LANGS_AND_MESSAGES).includes(locale.languageTag)) {
            activateLocale(locale.languageTag);

            return noop;
          }
          if (Object.keys(SUPPORTED_LANGS_AND_MESSAGES).includes(locale.languageCode)) {
            activateLocale(locale.languageCode);

            return noop;
          }
        }
      }
    }

    // default case otherwise, if no locale has been set
    if (i18n.locale === "") {
      activateLocale(DEFAULT_LOCALE);
    }

    return noop;
  }, [
    featureFlags?.experimentMemberAppPseudolocalization,
    featureFlags?.memberAppAllowAutoLanguageDetection,
    isFeatureFlagsFetched,
    locales,
    languageCode,
  ]);

  // If the fonts fail to load, we still want the app to load, but we will log that something is wrong
  if (fontsError !== null) {
    Log.warn("Failed to load fonts");
  } else if (fontsLoaded !== true) {
    return null;
  }

  const styles = StyleSheet.create<{
    readonly gestureHandler: ViewStyle;
  }>({
    gestureHandler: {
      flex: 1,
    },
  });

  return (
    <ErrorBoundary>
      {Platform.OS === "web" ? <AccessibilityWidget /> : null}
      <TextContextProvider maxFontSizeMultiplier={1.5}>
        <DdScreenReplayProvider>
          <I18nProvider i18n={i18n}>
            <AutoLogout>
              <SafeAreaProvider>
                <GestureHandlerRootView style={styles.gestureHandler}>
                  <StatusBar
                    backgroundColor={theme.colors.background.default}
                    barStyle="dark-content"
                  />
                  <Router />
                  <AlertContainer alertList={alertList} />
                  {Platform.OS === "web" ? <ReactQueryDevtools initialIsOpen={false} /> : null}
                </GestureHandlerRootView>
              </SafeAreaProvider>
            </AutoLogout>
          </I18nProvider>
        </DdScreenReplayProvider>
      </TextContextProvider>
    </ErrorBoundary>
  );
};

export { App };
