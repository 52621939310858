import { Notification as DesktopNotification } from "./Notification.desktop";
import { Notification as MobileNotification } from "./Notification.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";
import type { NotificationProps } from "./types";

export const Notification: FC<NotificationProps> = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotification {...props} />;
  }

  return <MobileNotification {...props} />;
};
