import { Body, useTheme } from "@merit/frontend-components";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useMemo, useState } from "react";
import type { ReactElement } from "react";
import type { TextStyle, ViewStyle } from "react-native";

type Tab = {
  readonly id: string;
  readonly title: string;
  readonly content: ReactElement;
};

type TabsProps = {
  readonly tabs: readonly Tab[];
};

export const Tabs = ({ tabs }: TabsProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly selectedTab: ViewStyle;
    readonly selectedTabText: TextStyle;
    readonly tab: ViewStyle;
    readonly tabContentContainer: ViewStyle;
    readonly tabHeaderContainer: ViewStyle;
    readonly tabText: TextStyle;
  }>({
    selectedTab: {
      borderBottomColor: theme.colors.border.pressed,
      borderBottomWidth: 2,
    },
    selectedTabText: {
      color: theme.colors.text.default,
    },
    tab: {
      paddingHorizontal: theme.spacing.m,
      paddingVertical: theme.spacing.m,
    },
    tabContentContainer: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
    tabHeaderContainer: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    tabText: {
      ...theme.fontSizes.s,
      color: theme.colors.text.subdued,
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  const [selectedTab, setSelectedTab] = useState<Tab["id"] | undefined>(tabs[0]?.id);

  const getTestProps = useGetTestProps();

  const tabNode = useMemo(() => {
    const matchingTab = tabs.find(tab => tab.id === selectedTab);

    return matchingTab?.content;
  }, [selectedTab, tabs]);

  return (
    <>
      <View style={styles.tabHeaderContainer}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {tabs.map(({ id: tabId, title }) => (
            <Pressable
              key={tabId}
              onPress={() => {
                setSelectedTab(tabId);
              }}
              style={StyleSheet.compose(
                styles.tab,
                selectedTab === tabId ? styles.selectedTab : null
              )}
              {...getTestProps({
                elementId: tabId,
                elementName: "TabsHeader",
              })}
            >
              <Body
                style={StyleSheet.compose(
                  styles.tabText,
                  selectedTab === tabId ? styles.selectedTabText : null
                )}
              >
                {title}
              </Body>
            </Pressable>
          ))}
        </ScrollView>
      </View>
      <View style={styles.tabContentContainer}>{tabNode}</View>
    </>
  );
};
