import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Body, useTheme } from "@merit/frontend-components";
import { CredentialListItem } from "./CredentialListItem";
import { Error } from "../../Error";
import { FilesAndMediaBlobFieldListItem } from "@src/components";
import { FilesAndMediaListItemSkeleton } from "./FilesAndMediaListItemSkeleton";
import { Trans } from "@lingui/macro";
import { getFilesAndMediaFieldsFromContainerFields } from "@src/utils";
import { useCredentials } from "@src/api/person-experience-backend/useCredentials";
import { useMemo } from "react";
import { useMerits } from "@src/api/issuance";
import { v4 as uuidv4 } from "uuid";
import type { Container, ContainerField } from "@merit/issuance-client";
import type { ExportedCredential } from "@merit/person-experience-backend-client";
import type { TextStyle, ViewStyle } from "react-native";

type BlobFieldItem = {
  readonly kind: "BlobField";
  readonly data: ContainerField;
};

type CredentialItem = {
  readonly kind: "Credential";
  readonly data: ExportedCredential;
};

type LoadingIndicatorItem = {
  readonly kind: "LoadingIndicator";
  readonly data: undefined;
};

type Item = BlobFieldItem | CredentialItem | LoadingIndicatorItem;

type MeritTabFilesAndMediaProps = {
  readonly meritId: Container["id"];
};

export const MeritTabFilesAndMedia = ({ meritId }: MeritTabFilesAndMediaProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly text: TextStyle;
    readonly listContainer: ViewStyle;
    readonly itemSeparator: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
    },
    itemSeparator: {
      marginBottom: theme.spacing.s,
    },
    listContainer: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
    },
    text: {
      color: theme.colors.text.default,
    },
  });

  const { data: merits, isError, isLoading } = useMerits();
  const credentialsQuery = useCredentials([meritId]);

  const matchingMerit = useMemo(
    () => merits?.find(merit => merit.id === meritId),
    [merits, meritId]
  );

  const items = useMemo<readonly Item[]>(() => {
    /* eslint-disable functional/prefer-readonly-type, no-underscore-dangle, functional/immutable-data, lingui/no-unlocalized-strings */
    const _items: Item[] = [];

    const filesAndMediaFields = getFilesAndMediaFieldsFromContainerFields(
      matchingMerit?.fields ?? []
    ).filter(field => (field.value?.length ?? 0) > 0);
    _items.push(...filesAndMediaFields.map(field => ({ data: field, kind: "BlobField" as const })));

    const credential = credentialsQuery.data?.exportedCredentials[meritId];
    if (credential !== undefined) {
      _items.push({ data: credential, kind: "Credential" });
    }

    if (credentialsQuery.isInitialLoading) {
      _items.push({ data: undefined, kind: "LoadingIndicator" });
    }

    return _items;
    /* eslint-enable */
  }, [
    matchingMerit?.fields,
    credentialsQuery.data?.exportedCredentials,
    credentialsQuery.isInitialLoading,
    meritId,
  ]);

  if (isError) {
    return (
      <View style={styles.container}>
        <Error />
      </View>
    );
  }

  if (isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (items.length === 0) {
    return (
      <View style={styles.container}>
        <Body style={styles.text}>
          <Trans>No media fields exist on this merit</Trans>
        </Body>
      </View>
    );
  }

  const keyExtractor = (item: Item) => {
    if (item.kind === "BlobField") {
      return item.data.templateFieldID ?? item.data.name ?? "";
    }

    if (item.kind === "Credential") {
      return item.data.url;
    }

    return `loadingIndicator-${uuidv4()}`;
  };

  const renderItem = (item: Item) => {
    if (item.kind === "BlobField") {
      return <FilesAndMediaBlobFieldListItem containerField={item.data} />;
    }

    if (item.kind === "Credential") {
      return <CredentialListItem credential={item.data} />;
    }

    return <FilesAndMediaListItemSkeleton />;
  };

  return (
    <View style={styles.listContainer}>
      {items.map((item, index) => (
        <View key={keyExtractor(item)}>
          {index > 0 && <View style={styles.itemSeparator} />}
          {renderItem(item)}
        </View>
      ))}
    </View>
  );
};
