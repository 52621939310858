/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable functional/no-loop-statement */
/* eslint-disable functional/immutable-data */
/* eslint-disable @typescript-eslint/no-non-null-assertion*/
/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
/* eslint-disable functional/no-let */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable no-await-in-loop */

import { Log, isNotUndefined, unloadAbortController } from "@src/utils";
import {
  isAccountMerit,
  toMerit,
  withContainerKind,
  withFieldMap,
  withTransformedFields,
} from "./utils";
import { isMerit } from "./utils/isMerit";
import { useAlerts, useConfig, useLogoutOnError, useMeritAuth0 } from "@src/hooks";
import { useContainersApi } from "./useContainersApi";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import type { Container } from "@merit/issuance-client";
import type { Merit } from "./types";
import type { MeritUserInfo } from "@src/types/user";
import type { UseQueryResult } from "@tanstack/react-query";

type QueryKey = readonly ["merits", { readonly entityID?: MeritUserInfo["entityID"] }];

export const useMeritsQueryKey = (): QueryKey => {
  const { user } = useMeritAuth0();

  return ["merits", { entityID: user?.entityID }];
};

export type UseMerits = () => UseQueryResult<readonly Merit[]>;

const getStartingAfter = (container?: Container) => {
  if (container === undefined) {
    return undefined;
  }

  if (container.authorizedAt === undefined || container.authorizedAt === null) {
    return undefined;
  }

  return `${container.authorizedAt},${container.id}`;
};

export const useMerits: UseMerits = () => {
  const config = useConfig();
  const { api: containersApi, isReady } = useContainersApi();
  const { sendAlert } = useAlerts();
  const queryKey = useMeritsQueryKey();
  const { onError } = useLogoutOnError();

  // useQuery here rather than useInfiniteQuery
  //
  // - useInfiniteQuery doesn't have an option to fetch multiple pages at once
  // - useInfiniteQuery data is ready after the first page
  // - we want to do validation on the entire set of merits
  // - paging and validating within the query function react-query to catch any errors that arise
  const result = useQuery<readonly Merit[], unknown, readonly Merit[], QueryKey>({
    enabled: isReady && config.remote !== undefined,
    onError,
    queryFn: async ({ queryKey }) => {
      let hasMore: boolean | undefined = true;
      let startingAfter;
      const pages = [];

      const { entityID } = queryKey[1];

      while (hasMore === true && pages.length < 50) {
        const response = await containersApi.getContainers(
          {
            limit: 100,
            // as a Person, it is important to provide recipientId, or else an empty array will be returned
            recipientId: entityID,
            sortBy: "authorizedAt",
            startingAfter,
          },
          { signal: unloadAbortController.signal }
        );

        const container = (response.containers ?? []).at(-1);

        pages.push(response.containers);
        startingAfter = getStartingAfter(container);
        hasMore = response.hasMore;
      }

      const merits = pages
        .flat()
        .filter(isNotUndefined)
        .map(container => withContainerKind(container, config))
        .map(withFieldMap)
        .filter(isMerit)
        .map(toMerit)
        .map(withTransformedFields);

      if (merits.length === 0) {
        Log.error("No merits returned from issuance", { userEntityId: entityID });
        throw new Error("Something went wrong while fetching merits");
      }

      if (merits.find(isAccountMerit) === undefined) {
        Log.error("Account merit not returned from issuance", { userEntityId: entityID });
        throw new Error("Something went wrong while fetching merits");
      }

      return merits;
    },
    queryKey,
    refetchInterval: 30000,
  });

  useEffect(() => {
    if (result.isError) {
      sendAlert({
        id: "useMerits-FailedToFetch",
        text1: String(result.error),
        type: "error",
      });
    }
  }, [result.isError, result.error, sendAlert]);

  return result;
};
