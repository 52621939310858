import { Log, findAndMerge } from "@src/utils";
import { PatchContainerStateEnum } from "@merit/issuance-client";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useContainersApi } from "./useContainersApi";
import { useLingui } from "@lingui/react";
import { useMerits, useMeritsQueryKey } from "./useMerits";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { Merit } from "./types";

export const useRejectMerit = () => {
  const { api: containersApi } = useContainersApi();
  const { sendAlert } = useAlerts();
  const { refetch } = useMerits();
  const queryKey = useMeritsQueryKey();
  const queryClient = useQueryClient();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: (meritId: Merit["id"]) =>
      containersApi.patchContainer({
        containerID: meritId,
        properties: {
          state: PatchContainerStateEnum.Rejected,
        },
      }),
    onError: (err, meritId) => {
      // We could potentially roll back here. Currently we just refetch onSettled.
      Log.error(`Error rejecting merit ${meritId} ${String(err)}`);
      sendAlert({
        id: "useRejectMerit-Error",
        text1: _(msg`Error rejecting your merit`),
        text2: String(err),
        type: "error",
      });
    },
    onMutate: async meritId => {
      sendAlert({
        id: "useRejectMerit-Rejecting",
        text1: _(msg`Rejecting…`),
        type: "info",
      });

      // Cancel any outgoing refetches, so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const merits: readonly Merit[] | undefined = queryClient.getQueryData(queryKey);

      if (merits === undefined) {
        return undefined;
      }

      const newMerits = findAndMerge(merits, m => m.id === meritId, {
        state: { name: PatchContainerStateEnum.Rejected, occurredAt: new Date().toISOString() },
      });

      if (newMerits === undefined) {
        return undefined;
      }

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, newMerits);

      // Return a context object with the snapshotted value
      return { merits };
    },
    onSettled: () => {
      refetch();
    },
    onSuccess: () => {
      sendAlert({
        id: "useRejectMerit-Success",
        text1: _(msg`Your Merit has been rejected`),
        type: "success",
      });
    },
  });
};
