import { useLogout } from "./auth";
import type { ResponseError } from "@merit/issuance-client";

type UseLogoutOnError = () => {
  readonly onError: (error: unknown) => void;
};

export const useLogoutOnError: UseLogoutOnError = () => {
  const logout = useLogout();

  const onError = (error: unknown) => {
    if ((error as Partial<ResponseError> | undefined)?.response?.status === 401) {
      logout();
    }
  };

  return { onError };
};
