import { Body, Icon, useTheme } from "@merit/frontend-components";
import { NotificationLayout } from "../NotificationLayout";
import { Pressable, StyleSheet } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { TruncatedBody } from "@src/components";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import a from "indefinite";
import type { FC } from "react";
import type { NotificationProps } from "./types";

export const Notification: FC<NotificationProps> = ({ container, onClose }) => {
  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const route = useRoute();
  const { theme } = useTheme();
  const { _ } = useLingui();

  const styles = StyleSheet.create({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    closeIcon: {
      height: 20,
      width: 20,
    },
    icon: {
      height: 32,
      width: 32,
    },
  });

  const handleClose = () => {
    onClose?.(container);
  };

  const handlePress = () => {
    navigation.navigate("MeritDetails", { meritId: container.id });
  };

  const containerName = container.name ?? "";
  const orgName = container.transformedFields.orgName;
  const article = a(container.name ?? "", { articleOnly: true });

  return (
    <Pressable
      onPress={handlePress}
      {...getTestProps(
        { elementId: container.id, elementName: "Notification" },
        { componentName: "Container" }
      )}
    >
      <NotificationLayout
        body={
          <TruncatedBody
            numberOfLines={2}
            {...getTestProps(
              { elementId: container.id, elementName: "Notification" },
              { componentName: "Body" }
            )}
          >
            <Trans comment={`article is either "a" or "an", depending on containerName`}>
              You have received {article}&nbsp;
              <Body style={styles.bold}>{containerName}</Body>&nbsp;merit from&nbsp;
              <Body style={styles.bold}>{orgName}</Body>
            </Trans>
          </TruncatedBody>
        }
        heading={_(msg`You received a merit`)}
        leftIcon={
          <Icon
            name="notificationDesktop"
            style={styles.icon}
            testProps={{ elementId: "icon", elementName: "Notification", screenName: route.name }}
          />
        }
        rightIcon={
          <Pressable
            onPress={handleClose}
            {...getTestProps(
              {
                elementId: container.id,
                elementName: "Notification",
              },
              { componentName: "CloseButton" }
            )}
          >
            <Icon name="closeSmallDefault" style={styles.closeIcon} />
          </Pressable>
        }
      />
    </Pressable>
  );
};
