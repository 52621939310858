import { Button, useTheme } from "@merit/frontend-components";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import type { MeritActionsProps } from "./types";
import type { ViewStyle } from "react-native";

export const MeritActions = ({ actions }: MeritActionsProps) => {
  const getTestProps = useGetTestProps();
  const { bottom: bottomSafeInset } = useSafeAreaInsets();
  const { theme } = useTheme();
  const { width } = useWindowDimensions();

  const primaryActionWidth = width > 400 ? 250 : 200;

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly actionButton: ViewStyle;
    readonly primaryAction: ViewStyle;
  }>({
    actionButton: {
      marginRight: theme.spacing.l,
    },
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row-reverse",
      justifyContent: "flex-start",
      padding: theme.spacing.xxl,
      paddingBottom: Math.max(bottomSafeInset, theme.spacing.xxl),
    },
    primaryAction: {
      width: primaryActionWidth,
    },
  });

  if (actions.length === 0) {
    return null;
  }

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "MeritActions",
      })}
    >
      {actions.map(({ id, type, ...rest }) => (
        <View
          key={id}
          style={StyleSheet.compose(
            type !== "primary" && styles.actionButton,
            type === "primary" && styles.primaryAction
          )}
        >
          <Button type={type} {...rest} />
        </View>
      ))}
    </View>
  );
};
