import { unloadAbortController } from "@src/utils";
import { useAppApi } from "./useAppApi";
import { useLogoutOnError } from "@src/hooks";
import { useQuery } from "@tanstack/react-query";

type QueryKey = readonly ["appConfiguration"];

export const useAppConfigurationQueryKey = (): QueryKey => ["appConfiguration"];

export const useAppConfiguration = () => {
  const { api, isReady } = useAppApi();
  const queryKey = useAppConfigurationQueryKey();
  const { onError } = useLogoutOnError();

  return useQuery({
    enabled: isReady,
    onError,
    queryFn: () => api.getConfiguration({ signal: unloadAbortController.signal }),
    queryKey,
  });
};
