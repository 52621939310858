import { EmptyNotification as DesktopEmptyNotification } from "./EmptyNotification.desktop";
import { EmptyNotification as MobileEmptyNotification } from "./EmptyNotification.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const EmptyNotification: FC = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopEmptyNotification />;
  }

  return <MobileEmptyNotification />;
};
