import { getLocalConfig } from "@src/utils";

export const minimumLoginAuthScopesString = ["openid", "profile", "email", "offline_access"].join(
  " "
);

export const stellarAuthScopesString = [
  "create:agent_entity_link",
  "read:agent_entity_link",
  "create:persona",
  "read:container",
  "transition:container",
  "read:entity",
  "merge:entity",
].join(" ");

export const allAuthScopesString = [minimumLoginAuthScopesString, stellarAuthScopesString].join(
  " "
);

const config = getLocalConfig();
/**
 * As of 2024-01-29, the audience only needs to be set for the Auth0 Stellar API.
 * We (Merit) currently do not validate the `aud` field of JWTs, but setting this
 * on Auth0's end is one of the requirements to get a JWT with Stellar API specific
 * scopes (e.g. "read:container"). It is also important to ensure that the related
 * Member API Auth0 Application is authorized with the Auth0 Stellar API + scopes.
 *
 * As of 2024-02-27 for [PE-1244](https://claimyourmerit.atlassian.net/browse/PE-1244)
 * merge entity expected audience should now be the same as just the stellar API basepath
 */
export const audience = config.api.stellarApiBasePath;
