import "expo-dev-client";
import "react-native-gesture-handler";
import "react-native-get-random-values"; // Required for uuidv4
import { App } from "./src/App";
import { displaySplash, getLocalConfig, initDatadog, initUnloadAbortController } from "./src/utils";
import { LogBox, Platform } from "react-native";
import { createElement } from "react";
import { createRoot } from "react-dom/client";
import { registerRootComponent } from "expo";
import { enableMapSet } from "immer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./src/components/AuthProvider";

const config = getLocalConfig();

// Workaround for reanimated
// See https://github.com/software-mansion/react-native-reanimated/issues/3886
if (typeof process === "undefined") {
  window._frameTimestamp = null;
}

if (config.datadog.enabled) {
  initDatadog({
    environment: config.stellarEnv,
    serviceName: "member-app",
    ...config.datadog,
  });
}

if (Platform.OS === "web") {
  initUnloadAbortController();
}

LogBox.ignoreLogs(["Require cycle:"]);
displaySplash();
enableMapSet();

const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: Infinity, staleTime: Infinity } },
});

const AppWithProviders = () => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </AuthProvider>
);

if ("web" === Platform.OS) {
  const root = createRoot(document.getElementById("root") ?? document.getElementById("main"));
  root.render(createElement(AppWithProviders));
} else {
  registerRootComponent(AppWithProviders);
}
