/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */

import { EmptyNotification } from "../EmptyNotification";
import { List, Icon as PEIcon } from "@src/components";
import { MAX_PENDING_MERITS_BEFORE_HIDING_NOTIFICATIONS } from "./constants";
import { Notification } from "../Notification";
import { NotificationSkeleton } from "../NotificationSkeleton";
import { Pressable, StyleSheet, View } from "react-native";
import { hasState } from "@src/utils";
import { range } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { useMerits } from "@src/api/issuance";
import { useTheme } from "@merit/frontend-components";
import type { ListElement, ListState } from "@src/components/List";
import type { Merit } from "@src/api/issuance";

type State = {
  readonly displayedMerits: readonly Merit[] | undefined;
};

export const Notifications = () => {
  const { theme } = useTheme();
  const listRef = useRef<ListElement>(null);
  const { data: merits } = useMerits();

  const [listState, setListState] = useState<ListState>({
    hasNext: false,
    hasPrevious: false,
    index: 0,
  });
  const [state, setState] = useImmer<State>({
    displayedMerits: undefined,
  });

  // Initialize displayed merits
  useEffect(() => {
    setState(draft => {
      if (merits === undefined) {
        return;
      }
      const newDisplayedMerits = merits.filter(hasState(["pending"]));
      if (newDisplayedMerits.length === draft.displayedMerits?.length) {
        return;
      }
      draft.displayedMerits = newDisplayedMerits;
    });
  }, [setState, merits]);

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.default,
      flexDirection: "row",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.xl,
    },
    itemContainer: {
      flexDirection: "row",
    },
    itemSeparator: {
      width: theme.spacing.m,
    },
    nextPressable: {
      opacity: listState.hasNext ? 1 : 0,
      padding: theme.spacing.m,
    },
    previousPressable: {
      opacity: listState.hasPrevious ? 1 : 0,
      padding: theme.spacing.m,
    },
  });

  const handleNext = () => {
    listRef.current?.next();
  };

  const handlePrevious = () => {
    listRef.current?.previous();
  };

  const handleClose = (merit: Merit) => {
    setState(draft => {
      draft.displayedMerits = draft.displayedMerits?.filter(m => m.id !== merit.id);
    });
  };

  const handleStateChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (state: ListState) => {
      setListState(state);
    },
    [setListState]
  );

  if (state.displayedMerits === undefined) {
    return (
      <View style={styles.container}>
        <List
          data={range(2)}
          keyExtractor={item => String(item)}
          renderItem={() => (
            <View style={styles.itemContainer}>
              <NotificationSkeleton />
              <View style={styles.itemSeparator} />
            </View>
          )}
        />
      </View>
    );
  }

  if (state.displayedMerits.length > MAX_PENDING_MERITS_BEFORE_HIDING_NOTIFICATIONS) {
    return null;
  }

  if (state.displayedMerits.length === 0) {
    return (
      <View style={styles.container}>
        <EmptyNotification />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Pressable
        disabled={!listState.hasPrevious}
        onPress={handlePrevious}
        style={styles.previousPressable}
      >
        <PEIcon name="chevron_left" size={20} />
      </Pressable>
      <List
        data={state.displayedMerits}
        keyExtractor={item => item.id}
        onStateChange={handleStateChange}
        ref={listRef}
        renderItem={item => (
          <View style={styles.itemContainer}>
            <Notification
              container={item}
              onClose={merit => {
                handleClose(merit);
              }}
            />
            <View style={styles.itemSeparator} />
          </View>
        )}
      />
      <Pressable disabled={!listState.hasNext} onPress={handleNext} style={styles.nextPressable}>
        <PEIcon name="chevron_right" size={20} />
      </Pressable>
    </View>
  );
};
