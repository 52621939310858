import { Body, Icon, useTheme } from "@merit/frontend-components";
import { NotificationLayout } from "../NotificationLayout";
import { StyleSheet } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useRoute } from "@react-navigation/native";
import type { FC } from "react";
export const EmptyNotification: FC = () => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const route = useRoute();
  const { _ } = useLingui();
  const styles = StyleSheet.create({
    icon: {
      height: 32,
      width: 32,
    },
    marginBottom: {
      marginBottom: theme.spacing.s,
    },
  });

  return (
    <NotificationLayout
      body={
        <Body
          numberOfLines={3}
          style={styles.marginBottom}
          testProps={{
            elementId: "description",
            elementName: "EmptyNotification",
            screenName: route.name,
          }}
        >
          <Trans>No new notifications</Trans>
        </Body>
      }
      heading={_(msg`You're looking good`)}
      leftIcon={
        <Icon
          name="notificationDesktopEmpty"
          style={styles.icon}
          {...getTestProps({ elementId: "leftIcon", elementName: "EmptyNotification" })}
        />
      }
    />
  );
};
