// eslint-disable-next-line no-restricted-imports
import { LanguageSelect as MFCLanguageSelect } from "@merit/frontend-components";
import { View } from "react-native";
import { usePreferencesStore } from "@src/stores";
import { useUpsertPersonPreferences } from "@src/api/person-experience-backend";
import type { StyleProp, ViewStyle } from "react-native";

type LanguageSelectProps = {
  readonly style?: StyleProp<ViewStyle>;
};

const LOCALES = [
  "ar", // Arabic
  "de", // German
  "en", // English
  "es", // Spanish
  "fr", // French
  "ht", // Haitian Creole
  "ko", // Korean
  "ru", // Russian
  "tl", // Tagalog
  "vi", // Vietnamese
  "zh", // Chinese (Traditional)
] as const;

export const LanguageSelect = ({ style }: LanguageSelectProps) => {
  const currentLanguageCode = usePreferencesStore(state => state.languageCode);
  const setLanguageCode = usePreferencesStore(state => state.setLanguageCode);
  const upsertPersonPreferences = useUpsertPersonPreferences();

  return (
    <View style={style}>
      <MFCLanguageSelect
        defaultValue={currentLanguageCode ?? "en"}
        languages={LOCALES}
        onChange={({ languageCode }) => {
          setLanguageCode(languageCode);
          upsertPersonPreferences.mutate({
            body: {
              languageTag: languageCode,
            },
          });
        }}
      />
    </View>
  );
};
